import React from "react";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import { makeStyles } from "@material-ui/core/styles";

const churchesList = [
  {
    name: "Arlington Chinese Church",
    url: "https://www.accdfw.org"
  },
  // {
  //   name: "Asian American Baptist Church",
  //   url: "https://www.aabcdallas.org"
  // },
  {
    name: "Austin Chinese Church",
    url: "https://www.austinchinesechurch.org"
  },
  {
    name: "Chinese Baptist Church of Houston",
    url: "https://www.cbchouston.org"
  },
  // {
  //   name: "Chinese Christian Church of Baton Rouge",
  //   url: "https://www.cccbr.net"
  // },
  {
    name: "Clearlake Chinese Church",
    url: "https://www.clearlakechinesechurch.org"
  },
  {
    name: "Collin County Chinese Fellowship Church",
    url: "https://www.cccfc.org"
  },
  {
    name: "Dallas Chinese Bible Church",
    url: "https://www.dcbcsite.org"
  },
  {
    name: "Dallas Chinese Fellowship Church",
    url: "https://www.dcfc.org"
  },
  {
    name: "First Chinese Baptist Church of Dallas",
    url: "https://www.fcbcd.org"
  },
  {
    name: "First Chinese Baptist Church of San Antonio",
    url: "https://www.fcbcsa.org"
  },
  {
    name: "Fort Bend Community Church",
    url: "https://www.fbcchome.org"
  },
  {
    name: "Frisco Community Bible Church",
    url: "https://www.friscocbc.org"
  },
  {
    name: "Grace Chinese Baptist Church",
    url: "https://gracechinesebaptist.org/"
  },
  {
    name: "Houston Chinese Church",
    url: "https://www.hcchome.org"
  },
  {
    name: "Katy Christian Community Church",
    url: "https://english.katyccc.org/"
  },
  {
    name: "New Hope Christian Church Woodlands",
    url: "https://www.nhcclife.com/"
  },
  {
    name: "Plano Chinese Alliance Church",
    url: "https://www.pcactexas.org"
  },
  {
    name: "West Houston Chinese Church",
    url: "https://www.whcchome.org"
  }
];

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.primary.contrastText
  },
  title: {
    borderBottom: "1px solid #f4f4f4"
  },
  list: {
    padding: "0 0 1rem 0"
  },
  listItemGutters: {
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3)
  }
}));

const LinkList = ({}) => {
  const classes = useStyles();

  return (
    <Box className={classes.root} boxShadow={5} position="sticky">
      <Box px={3} py={2} className={classes.title}>
        <Typography variant="h6">Partnering Churches</Typography>
      </Box>
      <List
        // dense={true}
        classes={{
          padding: classes.list
        }}
      >
        {churchesList.map(church => (
          <ListItem
            button
            component="a"
            href={church.url}
            // onClick={() => {
            //   window.location = church.url;
            // }}
            classes={{
              gutters: classes.listItemGutters
            }}
          >
            <ListItemText primary={church.name} />
          </ListItem>
        ))}
      </List>
    </Box>
  );
};

export default LinkList;
