import React from "react";
import PropTypes from "prop-types";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import Card from "@material-ui/core/Card";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import Button from "@material-ui/core/Button";
import { makeStyles } from "@material-ui/core/styles";
import LazyLoad from "react-lazyload";

const useStyles = makeStyles(theme => ({
  root: {
    // maxWidth: 345
    marginBottom: theme.spacing(5)
  },
  media: {
    height: 240
  }
}));

const Event = ({ title, children, imgSrc, date, url, location }) => {
  const classes = useStyles();
  const cardActionAreaProps = {};
  if (url) {
    cardActionAreaProps.component = "a";
    cardActionAreaProps.href = url;
  }
  return (
    <Card className={classes.root}>
      <CardActionArea {...cardActionAreaProps}>
        <LazyLoad height={240}>
          <CardMedia className={classes.media} image={imgSrc} title={title} />
        </LazyLoad>
        <CardContent>
          <Typography variant="h5" component="h2">
            {title}
          </Typography>
          <Typography variant="caption">
            {date} at {location}
          </Typography>
          <Typography variant="body1">{children}</Typography>
        </CardContent>
      </CardActionArea>
      {/*<CardActions>
        <Button size="small" color="primary" component="a" href={url}>
          Learn More
        </Button>
      </CardActions>*/}
    </Card>
  );
};

// Event.propTypes = {
//   : PropTypes.
// };

export default Event;
