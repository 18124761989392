import React from "react";
import Event from "./Event";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";

const events = {
  thrive: {
    title: "THRIVE Retreat",
    imgSrc: "/img/thrive_sm.jpg",
    url: "http://thrive.paactexas.org/",
    location: "Trinity Pines, TX",
    date: "May 22-25, 2020"
  },
  render: {
    title: "RENDER Retreat",
    imgSrc: "/img/render_crossroads_sm.jpg",
    url: "http://render.paactexas.org/",
    location: "Trinity Pines, TX",
    date: "Jan 13-16, 2021"
  },
  surrender: {
    title: "SURRENDER Retreat",
    imgSrc: "/img/surrender_sm.jpg",
    url: "http://surrender.paactexas.org/",
    location: "Trinity Pines, TX",
    date: "Date TBD"
  },
  cmc: {
    title: "Chinese Mission Convention - South",
    imgSrc: "/img/cmc_sm.jpg",
    url: "https://www.afcinc.org/en-us/mission/aboutcmc.aspx",
    location: "Dallas",
    date: "Dec 2021"
  },
  pastors: {
    title: "PAACT Pastors Gathering",
    imgSrc: "/img/pastors_sm.jpg",
    location: "Dallas Chinese Bible Church",
    date: "Aug 2019"
  }
};

const useStyles = makeStyles({
  title: {
    // borderBottom: "1px solid #f4f4f4"
  }
});

const Events = () => {
  const classes = useStyles();

  return (
    <Box>
      {/*<Box className={classes.title} pb={2}>
        <Typography variant="h4">Our Events</Typography>
      </Box>*/}
      <Event {...events.thrive}>
        <p>
          THRIVE is a retreat designed to refresh and empower you to thrive
          wherever God has you.
        </p>
        <p>
          God places us in different contexts. It could be school or the
          workplace. It could be in a season of singleness or the early years of
          marriage. It might be in a new ministry context or you may have been
          serving there a long time. Regardless of where we are, how do we
          thrive as Christians wherever God has us?
        </p>
        <p>
          Join us Memorial Day weekend, May 22-25, as we gather with career
          professionals from churches around Texas. Jared C. Wilson - author of
          numerous books, editor at For The Church, and professor at Midwestern
          Baptist Theological Seminary - will help us discover what it means to
          thrive as Christians.
        </p>
      </Event>
      <Event {...events.render}>
        <p>
          Psalm 116:12, "What shall I render to the Lord for all His benefits
          toward me?"
        </p>
        <p>
          RENDER is a tri-annual retreat that aims to support and encourage
          English-speaking Asian-Americans in the pursuit of vocational ministry
          within the local church.
        </p>
        <p>
          RENDER is distinctive in that there is a low mentor-attendee ratio,
          about 1:4. The mentors are either those involved in local church
          ethnic ministry or their spouses. They are able to give the RENDER
          attendees a realistic picture of what ethnic church ministry involves,
          as well as detailing the urgent need.
        </p>
        <p>Attendees at RENDER have the opportunity to:</p>
        <ul>
          <li>Engage in God-honoring worship</li>
          <li>
            Listen to messages delivered by a seasoned pastor of an ethnic
            church
          </li>
          <li>
            Attend workshops designed to help them prepare for fulltime ministry
          </li>
          <li>
            Have one-on-one time with a mentor to help them through the process
            of seeking God’s will
          </li>
        </ul>
        <p>Stay tuned for RENDER 2021 (Jan 13-16, 2021; Trinity Pines)</p>
      </Event>
      <Event {...events.surrender}>
        <p>
          SURRENDER is a tri-annual retreat that aims to support and encourage
          English-speaking Asian-Americans in the pursuit of cross-cultural
          missionary work.
        </p>
        <p>
          SURRENDER is a two-day retreat that helps participants explore God’s
          call. Participants wrestle with questions like:
        </p>
        <p>Attendees at SURRENDER have the opportunity to:</p>
        <ul>
          <li>How do I know if I am really called to the mission field?</li>
          <li>What does it take to be a missionary?</li>
          <li>How can I decide if I should go short-term or long-term?</li>
          <li>How can I prepare myself and seek confirmation?</li>
        </ul>
        <p>
          SURRENDER is designed to be an interactive, dynamic, experiential and
          spiritual time together. You will have an abundance of opportunities
          for personal reflection, prayer, learning and sharing with each other.
          Each participant will also be assigned a “mentor” for the retreat.
        </p>
        <p>Next SURRENDER TBD</p>
      </Event>
      <Event {...events.cmc}>
        <p>
          The Chinese Mission Convention (CMC) is the largest annual gathering
          of North American Chinese believers aimed at mobilizing attendees for
          cross-cultural missions. The convention is geared for Christians of
          all ages with separate Chinese and English language programs.
        </p>
        <p>
          CMC has been around since 1983 and is currently held annually on
          rotation in three regions of the States (West, East, and South). Past
          CMC SOUTH speakers have included David Platt, James Hudson Taylor IV,
          J.R. Vassar, Becky Pippert, Kenneth Bae, Thabiti Anyabwile, Mack
          Stiles, Bob Sjogren, and Gracia Burnham.
        </p>
        <p>
          PAACT supports the planning and execution of the English program for
          CMC SOUTH.
        </p>
        <p>Next CMC SOUTH scheduled for December 2021 in Dallas, TX</p>
      </Event>
      <Event {...events.pastors}>
        <p>Annual gathering of PAACT Pastors.</p>
      </Event>
    </Box>
  );
};

// Event.propTypes = {
//   : PropTypes.
// };

export default Events;
