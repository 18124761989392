import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import Container from "@material-ui/core/Container";

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1
  },
  menuButton: {
    marginRight: theme.spacing(2)
  },
  title: {
    flexGrow: 1,
    [theme.breakpoints.up("sm")]: {
      paddingLeft: theme.spacing(2)
    }
  },
  logo: {
    height: 32
  },
  AppBar: {
    // backgroundColor: theme.palette.background.default
  }
}));

export default function ButtonAppBar() {
  const classes = useStyles();

  return (
    <header className={classes.root}>
      <AppBar position="static" className={classes.AppBar}>
        {/*<Container>*/}
        <Toolbar>
          {/*<IconButton
            edge="start"
            className={classes.menuButton}
            color="inherit"
            aria-label="menu"
          >
            <MenuIcon />
          </IconButton>*/}
          <Box className={classes.title}>
            <img
              src="/img/PAACT-Only.png"
              // src="/img/PAACT-Logo-horizontal-lr.png"
              alt="logo"
              className={classes.logo}
            />
          </Box>
          {/*<Typography variant="h6" className={classes.title}>
          </Typography>*/}
          {/*<Button color="inherit">Login</Button>*/}
        </Toolbar>
        {/*</Container>*/}
      </AppBar>
    </header>
  );
}
