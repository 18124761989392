import React from "react";
import Header from "components/Header";
import Footer from "components/Footer";
import LinkList from "components/LinkList";
import Events from "components/Events/Events";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import { ThemeProvider } from "@material-ui/styles";
import CssBaseline from "@material-ui/core/CssBaseline";
import theme from "themes/default";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles({
  title: {
    // textTransform: "uppercase"
  },
  sideBar: {
    [theme.breakpoints.up("md")]: {
      paddingLeft: theme.spacing(8)
    },
    [theme.breakpoints.up("lg")]: {
      paddingLeft: theme.spacing(5)
    }
  }
});

function App() {
  const classes = useStyles();

  return (
    <>
      <CssBaseline />
      <ThemeProvider theme={theme}>
        <Box className="App">
          <Header />
          <Box pt={6}>
            <Container maxWidth="lg">
              <Grid container>
                <Grid item xs={12} md={7} lg={8}>
                  <Box pb={1}>
                    <Typography
                      className={classes.title}
                      variant="h4"
                      component="h2"
                    >
                      What is PAACT?
                    </Typography>
                  </Box>
                  <Box pb={6}>
                    <Typography variant="h6">
                      Partnership of Asian American Churches in Texas (PAACT) is
                      a fellowship of evangelical churches whose mission is to
                      assist the local church in advancing ministries among
                      English-speaking Asians.
                    </Typography>
                  </Box>
                  <Events />
                </Grid>
                <Grid item xs={12} md={5} lg={4} className={classes.sideBar}>
                  <Box position="sticky" top={0}>
                    <LinkList />
                  </Box>
                </Grid>
              </Grid>
            </Container>
          </Box>
          <Footer />
        </Box>
      </ThemeProvider>
    </>
  );
}

export default App;
