import React from "react";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import Container from "@material-ui/core/Container";
import FacebookIcon from "@material-ui/icons/Facebook";
import IconButton from "@material-ui/core/IconButton";

const Footer = ({}) => (
  <Box py={10}>
    <Container maxWidth="lg">
      <Typography variant="caption">
        Copyright © 2020. All Rights Reserved.
      </Typography>
      <IconButton
        aria-label="follow"
        component="a"
        href="https://www.facebook.com/paactexas/"
      >
        <FacebookIcon />
      </IconButton>
    </Container>
  </Box>
);

export default Footer;
